.itemTable {
  overflow-x: auto;
}
.pItems {
  position: relative;
  /* max-height: 200vh; */
  /* overflow-y: auto; */
  padding: 10px;
  /* min-width: 900px; */
}
.itemTable{
  width: 100%;
  margin-top: 10px;
}
.itemTable thead{
  background-color: red;
  color: white;
  font-weight: 800;
}
.itemTable th,.itemTable td{
  border: 1px solid red;
  padding: 10px 15px;
}
.itemTable th:first-of-type ,.itemTable th:last-of-type{
  width: 70px;
  text-align: center;
}
.itemTable td:first-of-type ,.itemTable td:last-of-type{
  /* width: 70px; */
  text-align: center;
}
.itemTable th:nth-of-type(2),.itemTable td:nth-of-type(2){
  width: 170px;
  text-align: center;
}
.itemTable th:nth-of-type(3),.itemTable td:nth-of-type(3){
  width: 35%;
  /* text-align: center; */
}
.itemTable td:nth-of-type(4),.itemTable th:nth-of-type(4){
  text-align: right;
  width: 35%;
}
.itemCard,
.itemDetailHead {
  /* display: grid; */
  /* grid-template-columns: repeat(5, minmax(100px, 1fr)); */
  /* grid-template-columns: [first] 50px [line2] 150px [line3] 230px [col4-start] 230px [five] 50px [end]; */
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;
}
.itemCard p,.itemCard h3{
  border-right: 1px solid red;
}
/* .itemDetailHead h5:nth-of-type(1){
  width: 50px;
  grid-area: 5/2;
} */
.dashboardTable{
    display: grid;
    grid-template-columns: repeat(7, minmax(100px, 1fr)) !important;
}
.itemDetailHead {
  position: sticky;
  top: 0px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 6px gainsboro;
  background-color: white;
  text-align: center;
}

.itemCard {
  /* width: 300px; */
  /* max-width: 300px; */
  margin-top: 10px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 6px gainsboro;
  /* margin: 10px; */
  text-align: center;
}
.itemHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  /* justify-content: center; */
}

.page-item.active .page-link{
  background-color: red !important;
  border-color: red !important;
  color: white !important;
}
.page-link{
  color: red !important;
}
.page-link:focus{
  box-shadow: none !important;
}
/* filter section  */

.filterSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  padding-bottom: 20px;
}
.searchBar {
  position: relative;
}
.searchBar input {
  width: 300px;
  padding: 10px;
  border: 1px solid red;
  border-radius: 5px;
  outline: none;
  color: red;
}
.srIcon {
  position: absolute;
  right: 10px;
  top: 15px;
  color: red;
}
.selectFilter select {
  width: 200px;
  border: 1px solid red;
  color: red;
  border-radius: 5px;
  padding: 10px;
  outline: none;
}

.mobHead {
  display: none;
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  .itemTable th:nth-of-type(2),.itemTable td:nth-of-type(2){
    width: auto;
    text-align: center;
  }
  .itemTable th,.itemTable td{
    padding: 10px 5px;
  }
  .itemTable{
    font-size: .85rem;
    /* min-width: 450px; */
  }
  .itemDetailHead h5 {
    /* display: none ; */
    font-size: 1rem;
  }
  .itemDetailHead {
    padding: 10px;
    margin-top: 20px;
    /* grid-template-columns: repeat(5, minmax(50px, 1fr)); */
  grid-template-columns: [first] 50px [line2] 100px [line3] auto [col4-start] auto [five] 50px [end];

  }
  .mobHead {
    /* display: block; */
    font-size: 0.75rem;
    font-weight: bold;
  }

  .filterSection {
    flex-direction: column-reverse;
  }
  .selectFilter,
  .searchBar {
    width: 100%;
  }
  .searchBar {
    margin-top: 15px;
  }
  .selectFilter select,
  .searchBar input {
    width: 100%;
  }
  .itemCard {
    /* grid-template-columns: repeat(5, minmax(50px, 1fr)); */
  /* grid-template-columns: [first] 50px [line2] 100px [line3] auto [col4-start] auto [five] 50px [end]; */

    column-gap: 5px;
  }
  .pItems {
    /* min-width: 300px; */
    padding-top: 0px;
    min-width: 430px;
  }
  .itemCard {
    padding: 10px 0px;
  }
  /* .searchBar input{
        margin-top: 15px;
    } */
}

.printTable {
  display: none;
  /* opacity: 0; */
  /* width: 100%; */
  /* position: absolute;
  left: -1000vw;
  top: 0;
  width: 780px; */
  width: 100%;
}
/* @media(max-width:600px){
  .printTable{
    width: 160vw;
  }
} */
/* .printer{
    width: 100%;
} */
.printHead {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  /* margin: 20px 5px; */
  width: 100%;
  padding: 15px;
}
.printHead h1 {
  margin-bottom: 0px;
  font-size: 1.5rem;
}
.printHead img {
  width: 100px;
}
.pdfTable,
.pdfTable td,
.pdfTable th {
  border: 1px solid #ddd;
  text-align: center;
}

.pdfTable {
  border-collapse: collapse;
  width: 100%;
}

.pdfTable th,
.pdfTable td {
  padding: 5px;
  /* font-size: rem; */
}

.prntBtn {
  background-color: red !important;
  color: white !important;
  border: none !important;
  outline: none !important;
  border-radius: 10px !important;
  padding: 8px 20px !important;
  margin-left: 10px !important;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.cusTableContainer iframe {
  width: 100%;
}
.modal-body {
  /* padding: 0px !important; */
  text-align: center !important;
}
.modal-footer{
  align-items: center !important;
}
/* @media (min-width: 576px) {
  .modal-dialog {
    max-width: 90%;
    margin: 1rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 2rem);
  }
} */


.emptyTb{
  text-align: center;
  font-size: 1rem;
  margin: 20px 0px;
}

.pagination{
  min-width: 500px !important;
  
}

.pageNum{
  margin-bottom: 0px;
  /* box-shadow: 0px 0px 6px rgb(209, 209, 209); */
  border: 1px solid rgb(219, 219, 219);
  color: red;
  padding: 6px 20px;
  font-size: .9rem;
  margin: 0px 8px;
}

@media(max-width:450px){
  .itemTable th, .itemTable td{
    font-size: .75rem;
  }
}