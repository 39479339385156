.imgSlider .swiper {
  width: 100%;
  height: 100%;
}

.imgSlider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
   /* height: 400px; */
}

.imgSlider .swiper-slide img {
  display: block;
  width: 100%;
  height: 500px;
  /* object-fit: cover; */
}

/* .colorProgres{
  color: red;
  background-color: red !important;
} */
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
  background-color: red !important;
}
.swiper-button-next, .swiper-button-prev{
  color: red;
}

.mobV{
  display: none !important;
}

@media(max-width:800px){
  .imgSlider .swiper-slide img{
    height: 100%;
  }
}
@media(max-width:500px){
  .mobV{
    display: block !important;
  }
  .desktopV{
    display: none !important;
  }
}