.bikeModelContent{
    width: 95%;
    margin: 50px auto;
    
}

.bkMaddBox{
    width: 500px;
    margin: 0px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgb(145, 144, 144);
    text-align: center;
}

.bkMaddBox input{
    width: 100%;
    border: 1px solid red;
    padding: 8px;
    border-radius: 5px;
    outline: none;
    margin-top: 10px;
    
}
.mBtn{
    margin-top: 15px;
    text-align: center;
    background-color: red;
    color: white;
}

.ModelList{
    list-style: none;
    margin: 0px;
    width: 500px;
    margin: 0px auto;
    padding-left: 0px;

}
.ModelList li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
    margin-top: 10px;
    background-color: rgba(255, 0, 0, 0.452);
    padding: 7px;
    border-radius: 5px;
}
.ModelList p{
    margin-bottom: 0px;
    color: white;
    font-weight: 700;
}

@media(max-width:600px){
    .bkMaddBox,.ModelList{
        width: 100%;
    }
}