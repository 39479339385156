.site-footer
{
  background-color: red;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  /* color:#737373; */
  color: white;
  margin-top: 100px;
  /* box-shadow: 0px -130px 40px 0px rgba(243, 109, 109, 0.5) inset; */

}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:white;
  font-size:18px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px;
  font-weight: bold;
  
}
a{
  font-weight: 600;
}
.site-footer a
{
  /* color:#737373; */
  color: white;
  text-decoration: none;
}
.site-footer a:hover
{
  color : white;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block;
  font-weight: 600;
  
}
.footer-links a
{
  /* color:#737373 */
  color: white;
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color: white;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right;
    align-items: center;
    display: flex;
    width: 100%;
    /* justify-content: flex-end; */
    justify-content: center;
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  /* padding-bottom: 25px; */
  line-height:38px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  /* background-color:#33353d */
  background-color: white;
  color: red !important;
}
.copyright-text
{
  margin:0
} 
.copyright-text a{
  color:white;
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}

.mobF{
  display: none !important;
}

@media (max-width:767px)
{
  .fCompOne{
    margin-bottom: 0px !important;
  }
  /* .fCompOne ul{
    margin-bottom: 0px !important;

  } */
  .mobF{
    display: block !important;
  }
  .fCompTwo,.mobHide {
    display: none;
  }
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}


.fLogo{
  width: 200px;
}


@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
  .row>*{
    text-align: center;
  }
  .fLogo{
    margin: 0px auto;
  }
}