*{
  margin: 0px ;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.cusContainer{
  max-width: 1200px;
  margin: 0px auto;
}

.paraText{
  margin-bottom: 0px;
  font-size: .9rem;
}
.h3Text{
  margin-bottom: 0px;
  font-size: 1.15rem;
  color: red;
}
.smText{
  margin-bottom: 0px;
  font-size: .8rem;
}

.mrqueText{
  background-color: red;
  color: white;
  font-weight: 600;
}

@media(max-width:600px){
  .h3Text{
    font-size: .9rem;
  }
}
.spinClas{
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.loaderContianer{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.658);
}