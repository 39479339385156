
.headContainer{
    padding: 15px 10px;
    background-color:white;
    /* background-color: #bcbcbc; */
    position: sticky;
    top: 0px;
    box-shadow: 0px -30px 40px 0px rgba(243, 109, 109, 0.5) inset;
    z-index: 999;
}
.headContent{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1150px;
    margin: 0px auto;
}
.headContent a{
    color: inherit;
    text-decoration: none;
}
.headLogo{
    display: flex;
}

.headLogo hr{
    margin-left: 60px;
    width: 1px;
    background-color: white;
    border: 1px solid white;
    /* height: ; */
}
.headLogo img{
    /* color: white; */
    /* font-weight: bold; */
    /* font-size: 2.2rem; */
    width: 80px;
    object-fit: contain;
}
.navLinks{
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
    margin-left: -40px;
}
.navLinks a{
    font-size: 1.1rem;
    font-weight: 600;
    /* color: var(--small-text); */
    margin: 0px 10px;
    color: red;
}
.navLinks a:hover{
    /* opacity: .7; */
    color: var(--big-text);
}
.sLinks{
    display: flex;
    list-style: none;
    align-items: center;
    margin-bottom: 0px;
}
.sLinks a{
    margin: 0px 10px;
}

.sIcon{
    /* color: white !important; */
    /* padding: 6px 8px; */
    border-radius: 50%;
    padding-bottom: 2px;
    transition: .4s;
    font-size: 1.5rem;
}
.sIcon:hover{
    /* background-color: white; */
    color: var(--big-text) !important;
}
.btnLink{
    display: inline-block;
    width: 140px;
    /* border: 2px solid var(--big-text); */
    /* color: white !important; */
    padding: 8px 0px;
    text-align: center;
    border-radius: 40px;
    font-weight: 600;
    transition: .5s;
    /* background-color: var(--big-text); */
}

.menuBtn,.closeBtn,.mobSideBottm{
    display: none;
}
.sLogo{
    display: none;
}
.sLogo{
    width: 60%;
    margin: 0px auto;
    margin-top: auto;
    text-align: center;
    /* position: fixed; */
    /* bottom: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,0%); */
}

@media (max-width:800px){
    .headLinks{
        display: flex;
        flex-direction: column;
        position: absolute;
        /* right: -10px; */
        left: -10px;
        background-color: white;
        padding: 0px 0px;
        top: 61px;
        box-shadow: 0px 3px 9px rgba(0,0,0,0.2);
        transition: .3s;
        height: calc(100vh - 76px);
        width: 260px;
        border-top: 2px solid red;
        border-right: 2px solid red;
        text-align: center;
    }
    .sLogo{
        display: block;
        transform: translateY(40%);

        /* margin-top: 30px; */
    }
    .closeMenu{
        transform: translateX(-200%);

    }
    .mobSideBottm{
        /* position: fixed; */
        display: block;
        bottom: 0px;
        font-size: .6rem;
        padding: 0px 8px;
        text-align: center;
        font-weight: 600;
        border-top: 1px dashed red;
        padding-top: 10px;
        width: 100%;
        margin-top: auto;
    }
    .mobSideBottm a{
        color: red;
    }
    .openMenu{
        transform: translateX(0%);
        
    }
    .navLinks{
        transform: translateY(90%);
        flex-direction: column;
        /* align-items: center; */
        margin: 0px auto;
        /* justify-content: center; */
        align-items: flex-start;
        text-align: center;
        
    }
    .navLinks li{
        margin: 5px 0px;
        /* border-bottom: 1px solid red; */
        width: 100%;
        /* background-color: rgba(248, 139, 139, 0.356); */
        /* padding: 7px 0px; */
    }
    .btnLink{
        display: none;
    }
    .menuBtn,.closeBtn{
        display: block;
    }
    .menuBtn button{
        background-color: transparent;
        outline: none;
        border: none;
        font-size: 2rem;
        font-weight: bold;
        color: var(--big-text);
        margin-left: 10px;
    }
    .closeBtn button{
        background-color: transparent;
        outline: none;
        border: none;
        position: relative;
        width: 42px;
        top: 0px;
        right: 10px;
    }
    .closeBtn span{
        transition: 2s;
    }
    .sp1{
        display : inline-block;
        width: 30px;
        height: 3px;
        background-color: red;
        transform: rotate(45deg) ;
        position: absolute;
        /* transition: 1s; */
    }
    .sp2{
        display : inline-block;
        width: 30px;
        height: 3px;
        background-color: red;
        transform: rotate(-45deg) ;
        position: absolute;
        /* transition: 1s; */


    }
    .overLay{
        position: fixed;
        background-color: rgba(0, 0, 0, 0.473);
        width: 100%;
        height: 100vh;
        top: 76px;
        left: 0px;
    }
}