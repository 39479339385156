
.dsBtn{
    /* text-align: start ; */
    width: 600px;
    margin: 0px auto;
}
/* form section  */

.form_box{
    width: 600px;
    margin: 00px auto;
    border: 1px solid red;
    border-radius: 10px;
    padding: 20px;
    /* box-shadow: 0px 0px 6px rgb(48, 48, 48); */
}
.form_content,.itemForm,.form_content form,.formMd{
    width: 100%;
}
.form_box p{
    margin-bottom: 0px;
}
.item_inp {
    margin-top: 10px;
    width: 100%;
}
.item_inp label{
    width: 100%;
}
.item_inp input,.item_inp select{
    width: 100%;
    padding: 8px;
    /* border-radius: 1px solid; */
    /* border-radius: 8px; */
    font-size: 1.1rem;
    outline: none;
    margin-top: 5px;
}

.inpUrdu{
    direction: rtl;
}
.csBtn{
    width: 100%;
    /* margin-top: 20px; */
    border: 1px solid red;
    background-color: red;
    color: white;
}


/* table  */

.dashTableContainer{
    max-width: 1250px;
    margin: 50px auto;
    padding: 0px 15px;
}

@media(max-width:850px){
    .dashboardTable{
        grid-template-columns: repeat(7, minmax(40px, 1fr)) !important;
    }
    .dbTableSect{
        min-width: 820px !important;
    }
}
@media(max-width:650px){
    .form_box,.dsBtn{
        width: 95%;
    }
}