#contact {
  width: 100%;
  height: 100%;
}

.section-header {
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
  /* font: 300 60px 'Oswald', sans-serif; */
  /* color: #fff; */
  text-transform: uppercase;
  letter-spacing: 6px;
}

.contact-wrapper {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  margin: 0 auto;
  padding:0px 20px;
  position: relative;
  max-width: 840px;
  align-items: center;
}

/* Left contact page */
.form-horizontal {
  /*float: left;*/
  /* max-width: 400px; */
  /* font-family: "Lato"; */
  font-weight: 400;
  width: 60%;
  margin: 0px auto;
}

.form-control,
textarea {
  /* max-width: 400px; */
  /* background-color: #000; */
  /* color: #fff; */
  letter-spacing: 1px;
  /* border: 1px solid red; */
  border-radius: 6px;
  margin-top: 14px;
  background-color: rgba(255, 0, 0, 0.144);
  padding: 10px;
  border: none;
  box-shadow: 0px 3px 6px rgba(172, 170, 170, 0.363);
  
}
.form-control:focus{
  /* background-color: ; */
  box-shadow: 0px 3px 6px rgba(190, 190, 190, 0.363);
  border-color: transparent;
}
.cusBtnForm {
  width: 100%;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  padding: 10px 0px;
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: 0px 3px 6px rgba(134, 134, 134, 0.363);
  transition: 0.3s;
}
.cusBtnForm:hover {
  background-color: white;
  border: 1px solid red;
  color: red;
}
.send-button {
  margin-top: 15px;
  height: 34px;
  width: 400px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.alt-send-button {
  width: 400px;
  height: 34px;
  transition: all 0.2s ease-in-out;
}

.send-text {
  display: block;
  margin-top: 10px;
  font: 700 12px "Lato", sans-serif;
  letter-spacing: 2px;
}

.alt-send-button:hover {
  transform: translate3d(0px, -29px, 0px);
}

/* Begin Right Contact Page */
.direct-contact-container {
  /* max-width: 400px; */
  width: 60%;
  margin: 40px auto;
}

/* Location, Phone, Email Section */
.contact-list {
  list-style-type: none;
  margin-left: -30px;
  padding-right: 20px;
}

.list-item {
  /* line-height: 3; */
  /* color: #aaa; */
  color: red;
  font-size: 0.9rem;
  margin-top: 18px;
  display: flex;
  align-items: flex-start;
  /* justify-content: safe; */
}

.contact-text {
  /* font: 300 18px 'Lato', sans-serif; */
  letter-spacing: 1.9px;
  /* color: #bbb; */
  color: red;
}

.place {
  margin-left: 20px;
  width: 90%;
}

.phone {
  margin-left: 20px;
}

.gmail {
  margin-left: 20px;
}

.contact-text a {
  color: red;
  /* color: #bbb; */
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  /* color: #fff; */
  opacity: 0.7;
  text-decoration: none;
}
.fromIcon {
  font-size: 1.3rem;
  /* width: 20px; */
}

/* Social Media Icons */
.social-media-list {
  position: relative;
  font-size: 22px;
  /* text-align: center; */
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
}

.social-media-list li a {
  color: #fff;
}

.social-media-list li a {
  position: relative;
  /* display: inline-block; */
  height: 50px;
  width: 50px;
  margin: 10px 3px;
  line-height: 45px;
  border-radius: 50%;
  color: #fff;
  /* background-color: rgb(27,27,27); */
  background-color: red;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
  display: block;
}

.social-media-list li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:hover {
  background-color: #fff;
}

.social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}

.social-media-list li:hover a {
  color: #000;
}

.copyright {
  font: 200 14px "Oswald", sans-serif;
  color: #555;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
}

/* Begin Media Queries*/
@media screen and (max-width: 850px) {
  /* .contact-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  .direct-contact-container,
  .form-horizontal {
    margin: 0 auto;
  }
  .social-media-list{
    justify-content: center;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 100%;
    /* width: 50%; */
  }
  .social-media-list li {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
  .social-media-list li:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

@media screen and (max-width: 600px) {
  .form-horizontal{
    width: 90%;
  }
  .direct-contact-container{
    margin-top: 50px;
    width: 90%;
    /* max-width: 400px; */
  }
  .social-media-list {
    left: 0;
  }
}

@media screen and (max-width: 420px) {
  .form-horizontal{
    width: 100%;
  }
  .direct-contact-container{
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
  .list-item{
    font-size: .8rem;
  }
}

/* table schedule  */

.sdlSection {
  width: 600px;
  margin: 0px auto;
  /* border: 1px solid red; */
}
.responsive-table {
  padding-left: 0px;
  border: 2px solid red;
}
.responsive-table li {
  /* border-radius: 3px; */
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  border: 1px solid red;
  /* margin-bottom: 15px; */
}
.responsive-table .table-header {
  /* background-color: #fd2626; */
  background-color: red;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.03em;
  font-weight: 600;
}
.responsive-table .table-row {
  background-color: #fff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  color: red;
}
.responsive-table .col-1 {
  flex-basis: 40%;
}
.responsive-table .col-2 {
  flex-basis: 30%;
}
.responsive-table .col-3 {
  flex-basis: 30%;
}
/* .responsive-table .col-4 {
    flex-basis: 25%;
 } */
@media all and (max-width: 767px) {
  .sdlSection{
    width: 90%;
  }
}
@media(max-width:410px){
  .responsive-table li{
    font-size: .9rem;
    padding: 10px 20px;
  }
}