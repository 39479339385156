.titleContainer{
    padding: 3rem 0px;
    text-align: center;
    width: fit-content;
    /* border: 2px solid; */
    margin: 0px auto;
}
.titleContainer h1{
    font-size: 2.7rem;
}

.animLine{
    width: 100%;
    /* margin: 0px auto; */
    margin: 0px;
    border: 3px solid red;
    background-color: red;
    animation: anLine 3s  infinite  ease-in ;

}

@keyframes anLine{
    0%{
        width: 0px;
    }
    /* 25%{
        width: 100px;
    } */
    100%{
        width:100%;
    }
}


@media(max-width:600px){
    .titleContainer h1{
        font-size: 2rem;
    }
    .titleContainer{
        padding: 2rem 0px;
    }
}