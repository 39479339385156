.brandsImgs{
    display: flex;
    justify-content: space-between;
    
}
/* .brandContent img{
    width: 150px;
    height: 150px;
    display:inline-block;
    object-fit: contain;
    margin: 0px auto;
    display: flex;
    justify-content: center;
} */

/* .brandContent .swiper{
   padding-bottom: 50px !important;
} */
.brandContent .swiper {
    width: 100%;
    /* padding-top: 50px; */
    padding-bottom: 50px;
  }
  .swiper-pagination-bullet-active{
    background-color: red !important;
  }
 .brandsImgs .swiper-slide {
    background-position: center;
    background-size: cover;
    /* width: 300px; */
    /* height: 300px; */
  }
  
 /* .brandsImgs .swiper-slide img {
    display: block;
    width: 100%;
  } */
  .dealsText{
    text-align: center;
    color: red;
    text-shadow: 0px 3px 5px rgba(255, 0, 0, 0.74);
    font-size: 2.3rem;
    text-transform: uppercase;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media(max-width:600px){
    .brandContent img{
      width: 100px;
      height: 80px;
      object-fit: contain;
    }
    .dealsText{
      font-size: 1.5rem;
    }
  }
  @media(max-width:400px){
    .dealsText{
      font-size: .9rem;
    }
  }
  @media(max-width:350px){
    .brandContent img{
      width: 80px;
      height: 60px;
      object-fit: contain;
    }
  }