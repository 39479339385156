
.cardContent{
    display: grid;
    grid-template-columns: repeat(4, minmax(80px, 1fr));
    column-gap: 10px;
    row-gap: 20px;
}
.cusCard{
    max-width: 300px;
    width: 100%;
    box-shadow: 0px 0px 60px gainsboro;
    border-radius: 10px;
    cursor: pointer;
    transition: .25s;
}
.cusCard:hover{
    transform: scale(1.05);
}
.cusCard img{
    width: 100%;
}
.cardBody{
    padding: 30px;
    text-align: center;
}
.cardBody h3{
    font-weight: bold;
    font-size: 1.5rem;
}

.shBtn:hover{
    background-color: red;
    color: white;
    transition: 2s;
}

.crdBtn{
    text-align: center;
}

@media(max-width:1200px){
    .cardContent{
        grid-template-columns: repeat(3, minmax(80px, 1fr));
        padding: 0px 20px;
    }
    .cusCard{
        margin: 0px auto;
    }
}
@media(max-width:600px){
    .cardContent{
        grid-template-columns: repeat(2, minmax(80px, 1fr));
        /* padding: 0px 20px; */
    }
    .cusCard{
        margin: 0px auto;
    }
}