/* .btnContainer{
    text-align: center;
} */
.Linkbtn{
    display: inline-block;
    /* width: 150px; */
    padding: 10px 30px;
    border: 1px solid red;
    color: red ;
    text-align: center;
    text-decoration: none;
    border-radius: 10px;
    margin: 30px auto;
    transition: .2s;
}

.cusBtn{
    /* background-color: transparent; */
    border: none;
    outline: none;
    padding: 7px 30px;
    border-radius: 10px;
    /* background-color: red; */
    /* color: white; */
}